<template>
	<div>
		<CCard class="p-2">
			<form @submit.prevent="submit">
				<CCardHeader>
					<CRow style="color: #1352a1;">
				
						<CCol lg="6">
							<h5 v-if="!editMode">New Vehicle</h5>
							<h5 v-else>Update Vehicle</h5>
						</CCol>
				     	<CCol lg="6">
				      		<div style="text-align: right">
				      			<CButton size="sm" class="btn btn-ghost-dark mr-1" @click="backToTable()" >Cancel</CButton> 
					      		<CButton class="px-3 mr-1"  size="sm" color="info" type="submit">Save</CButton>
				      		</div>
						</CCol>
					</CRow>
				</CCardHeader>
				<CCardBody class="card-form">
                    <CCard class="pl-3 pr-3">
					<CRow class="mt-3">
                            <CCol lg="12">
                                <h6 >Vehicle Information </h6>
                                
                            </CCol>
                        </CRow>
                        <br/> 
						<CRow >
							<CCol lg="4">
								<label class="mb-1">Plate No. <span class="text-danger">*</span></label>
								<CInput
				                  v-model="dataParams.plate_no"
				                  required="true"
				                />
							</CCol>
							<CCol lg="4">
								<label class="mb-1">Body No. <span class="text-danger">*</span></label>
								<CInput
				                  v-model="dataParams.body_no"
				                  required="true"
				                />
							</CCol>
							<CCol lg="4">
								<div class="form-group"> 
									<label class="mb-1">Status <span class="text-danger">*</span></label>
									<v-select 
										label="setting_name" 
										:options="[
											{
												setting_name: 'Active',
												id: 'ACTIVE'
											},
											{
												setting_name: 'Inactive',
												id: 'INACTIVE'
											}, 
											{
												setting_name: 'Incoming',
												id: 'INCOMING'
											}, 
											{
												setting_name: 'Archive/Sold',
												id: 'ARCHIVE'
											},
											{
												setting_name: 'In-shop',
												id: 'IN-SHOP'
											},
											{
												setting_name: 'Out of Service',
												id: 'OUT OF SERVICE'
											},
										]"
										:reduce="item => item.id"
										v-model="dataParams.status"
										placeholder="-Select-" 
									>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.status"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select>
								</div>
							</CCol>
						
						</CRow>
						<CRow >
							<CCol lg="4">
								<div class="form-group"> 
									<label class="mb-1">Vehicle Type <span class="text-danger">*</span></label>
									<v-select 
										label="setting_name" 
										:options="vehicleTypeList.data"
										:reduce="item => item.id"
										v-model="dataParams.vehicle_type_id"
										placeholder="-Select-" 
									>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.vehicle_type_id"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select>
								</div>
							</CCol>
							
							
							<CCol lg="4">
								<div class="form-group"> 
									<label class="mb-1">Maker/Manufacturer <span class="text-danger">*</span></label>
									<v-select 
										label="setting_name" 
										:options="makerModelList.data"
										:reduce="item => item.id"
										v-model="dataParams.maker_model_id"
										
									>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.maker_model_id"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select>
								</div>
							</CCol>
							<CCol lg="4">
								<div class="form-group"> 
									<label class="mb-1">Model <span class="text-danger">*</span></label>
									<v-select 
										label="setting_name" 
										:options="vehicleModelList.data"
										:reduce="item => item.id"
										v-model="dataParams.model_id"
										
									>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.model_id"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select>
								</div>
							</CCol>
						
						</CRow>
						<CRow >
							<CCol lg="4">
								<div class="form-group">
									<label class="mb-1">Year</label>
									<CInput
					              
					                  v-model="dataParams.year" 
					                /> 
								</div> 
							</CCol> 
							
							
							<CCol lg="4">
								<div class="form-group"> 
									<label class="mb-1">Commodity Type </label>
									<v-select 
										label="setting_name" 
										:options="commodityTypeList.data"
										:reduce="item => item.id"
										v-model="dataParams.commodity_type_id"
										placeholder="-Select-" 
									>
										
									</v-select>
								</div>
							</CCol>
							<CCol lg="4">
								<label class="mb-1">Asset No.</label>
								<CInput
				                 
				                  v-model="dataParams.asset_no"
				                />
							</CCol>
							
						</CRow>
							
				</CCard>
				<CCard class="pl-2 pr-2">	
						<CRow class="mt-3">
								<CCol lg="12">
									<h6 >Vehicle Location </h6>
									
								</CCol>
						</CRow>
                        <br/> 
						<CRow >
							<CCol lg="4">
								<div class="form-group">
									<!-- <label for="exampleInputEmail1"> </label>  -->
									<CInputCheckbox 
										:label="'Company owned?'"
										:checked="dataParams.company_owned == 1 ? true : false"
					                    @update:checked="updateCompanyOwned" 
				                    /> 
					            </div>
							</CCol>
							<CCol lg="8">
								<div class="form-group" v-if="!dataParams.company_owned"> 
									<label class="mb-1">Subcon (Trucker)</label>
									<v-select 
										label="vendor_name" 
										:options="vendorList.data"
										:reduce="item => item.id"
										v-model="dataParams.vendor_id"
										placeholder="-Select-" 
									> 
									</v-select>
								</div>
							</CCol> 
						</CRow>
						<CRow >
							<CCol lg="4">
								<div class="form-group"> 
									<label class="mb-1">Origin</label>
									<v-select 
										label="setting_name" 
										:options="originList.data"
										:reduce="item => item.id"
										v-model="dataParams.origin_id"
										placeholder="-Select-" 
									> 
									</v-select>
								</div>
							</CCol>
							<CCol lg="4">
								<div class="form-group"> 
									<label class="mb-1">Depot</label>
									<v-select 
										:disabled="depot_id != null"
										label="setting_name" 
										:options="depotList.data"
										:reduce="item => item.id"
										v-model="dataParams.location"
										placeholder="-Select-" 
									> 
									</v-select>
								</div>
							</CCol>
							<CCol lg="4">
								<div class="form-group"> 
									<label class="mb-1">With GPS?</label>
									<v-select 
										label="setting_name" 
										:options="[
											{
												setting_name: 'Yes',
												id: 'yes'
											},
											{
												setting_name: 'No',
												id: 'no'
											},
										]"
										:reduce="item => item.id"
										v-model="dataParams.with_gps"
										
									> 
									</v-select>
								</div>
						</CCol> 
               
							
						</CRow>
						
				</CCard>
				<CCard class="pl-2 pr-2">	
						<CRow class="mt-3">
								<CCol lg="12">
									<h6 >Project Information </h6>
									
								</CCol>
						</CRow>
						<CRow >
								<CCol lg="6">
								<div class="form-group"> 
									<label class="mb-1">Project/Dept</label>
									<v-select 
										label="customer_name" 
										:options="customerList.data"
										:reduce="item => item.id"
										v-model="dataParams.project_id"
										placeholder="-Select-" 
									> 
									</v-select>
								</div>
							</CCol>
							<CCol lg="6">
								<div class="form-group">
									<label class="mb-1">Transfer Date</label>
									<Datepicker 
										input-class="form-control bg-white" 
										v-model="dataParams.transfer_date"
										
									>
									</Datepicker> 
								</div> 
							</CCol>
              <CCol lg="6">
                      <div class="form-group">
                        <label style="font-size: 12px" class="mb-1"
                          >Current Location</label
                        >
                        <v-select
                        @input="locationChanged($event)"
                          label="customer_name"
                          :options="currentLocationList.data"
                          :reduce="(item) => item.id"
                          v-model="dataParams.current_location" 
                          placeholder="-Select-"
                        >
                        
                        </v-select>
                      </div>
            </CCol>
						
						</CRow>
						
				</CCard>		
				<CCard class="pl-2 pr-2">	
						<CRow class="mt-3">
								<CCol lg="12">
									<h6 >Specifications </h6>
									
								</CCol>
						</CRow>
						<br/>
						
                        <CRow >
							<CCol lg="4">
								<label class="mb-1">Chassis No.</label>
								<CInput
				                 
				                  v-model="dataParams.chassis_no" 
				                />
							</CCol>
						
							<CCol lg="4">
								<label class="mb-1">Color</label>
								<CInput
				                
				                  v-model="dataParams.color" 
				                />
							</CCol>
							<CCol lg="4">
								<label class="mb-1">Engine No.</label>
								<CInput
				                 
				                  v-model="dataParams.engine_no"
				                />
							</CCol>
							<CCol lg="4">
								<label class="mb-1">Engine Size</label>
								<CInput
				                 
				                  v-model="dataParams.engine_size" 
				                />
							</CCol>
						
							<CCol lg="4">
								<div class="form-group"> 
									<label class="mb-1">Fuel Type</label>
									<v-select 
										label="setting_name" 
										:options="[
											{
												setting_name: 'Gasoline',
												id: 'GASOLINE'
											},
											{
												setting_name: 'Diesel',
												id: 'DIESEL'
											},
										]"
										:reduce="item => item.id"
										v-model="dataParams.fuel_type"
										placeholder="-Select-" 
									> 
									</v-select>
								</div>
							</CCol>
							<CCol lg="4">
								<div class="form-group"> 
									<label class="mb-1">Transmission Type</label>
									<v-select 
										label="setting_name" 
										:options="[
											{
												setting_name: 'Manual',
												id: 'MANUAL'
											},
											{
												setting_name: 'Automatic',
												id: 'AUTOMATIC'
											},
										]"
										:reduce="item => item.id"
										v-model="dataParams.transmission_type"
										placeholder="-Select-" 
									> 
									</v-select>
								</div>
							</CCol>
							
						</CRow>
				</CCard>

				<CCard class="pl-2 pr-2">	
						<CRow class="mt-3">
								<CCol lg="12">
									<h6 >Other Details </h6>
									
								</CCol>
						</CRow>
						<br/>
						<CRow >
							<CCol lg="4">
								<div class="form-group">
									<label class="mb-1">Acquisition Date</label>
									<Datepicker 
										input-class="form-control bg-white" 
										v-model="dataParams.acquisition_date"
										
									>
									</Datepicker> 
								</div> 
							</CCol>
							<CCol lg="4">
								<label class="mb-1">Acquisition Price</label>
								<CInput
				                  
				                  v-model="dataParams.acquisition_price" 
				                />
							</CCol> 
							<CCol lg="4">
								<div class="form-group">
									<label class="mb-1">Breakdown Date</label>
									<Datepicker 
										input-class="form-control bg-white" 
										v-model="dataParams.breakdown_date"
										
									>
									</Datepicker> 
								</div> 
							</CCol>
						</CRow>
						
						<CRow>
							<CCol lg="4">
								<div class="form-group">
									<label class="mb-1">CR Date</label>
									<Datepicker 
										input-class="form-control bg-white" 
										v-model="dataParams.cr_date"	
									>
									</Datepicker> 
								</div> 
						</CCol>
							<CCol lg="4">
								<label class="mb-1">CR Number </label>
								<CInput
				                
				                  v-model="dataParams.cr_no" 
				                />
						</CCol>
						
						</CRow>
						<CRow >
							<CCol lg="12">
								<label class="mb-1">Insurance Provider</label>
								<CInput
				                  
				                  v-model="dataParams.insurance_provider" 
				                />
							</CCol> 
						</CRow>
						<CRow >
							
							<CCol lg="6">
								<label class="mb-1">Insurance Policy No.</label>
								<CInput
				                 
				                  v-model="dataParams.insurance_policy_no" 
				                />
							</CCol>
							<CCol lg="6">
								<div class="form-group">
									<label class="mb-1">Insurance Expiry Date</label>
									<Datepicker 
										input-class="form-control bg-white" 
										v-model="dataParams.insurance_expiry"
									>
									</Datepicker> 
								</div> 
							</CCol>
						</CRow>
						<CRow >
							<CCol lg="6">
								<label class="mb-1">Inland Marine Policy No.</label>
								<CInput
				                 
				                  v-model="dataParams.in_marine_policy_no" 
				                />
							</CCol> 
							<CCol lg="6">
								<div class="form-group">
									<label class="mb-1">Inland Marine Expiry Date</label>
									<Datepicker 
										input-class="form-control bg-white" 
										v-model="dataParams.in_marine_expiry"
										
									>
									</Datepicker> 
								</div> 
							</CCol>
							<CCol lg="4">
								<div class="form-group">
									<label class="mb-1">Late Renewal Date</label>
									<Datepicker 
										input-class="form-control bg-white" 
										v-model="dataParams.later_renewal_date"
									>
									</Datepicker> 
								</div> 
							</CCol>
							<CCol lg="4">
								<label class="mb-1">LTFRB Case No.</label>
								<CInput
				                 
				                  v-model="dataParams.ltfrb_case_no" 
				                />
							</CCol>
							<CCol lg="4">
								<div class="form-group">
									<label class="mb-1">LTFRB Expiry Date</label>
									<Datepicker 
										input-class="form-control bg-white" 
										v-model="dataParams.ltfrb_expiry"
									>
									</Datepicker> 
								</div> 
							</CCol> 
							<CCol lg="4">
								<label class="mb-1">M.V. File No.</label>
								<CInput
				                  
				                  v-model="dataParams.mv_file_no" 
				                />
							</CCol>
							<CCol lg="4">
								<div class="form-group">
									<label class="mb-1">OR Date</label>
									<Datepicker 
										input-class="form-control bg-white" 
										v-model="dataParams.or_date"
										
									>
									</Datepicker> 
								</div> 
							</CCol>
							<CCol lg="4">
								<label class="mb-1">OR Number</label>
								<CInput
				                
				                  v-model="dataParams.or_no" 
				                />
							</CCol> 
							<CCol lg="4">
								<div class="form-group">
									<label class="mb-1">P.A Expiry Date</label>
									<Datepicker 
										input-class="form-control bg-white" 
										v-model="dataParams.pa_date"
										
									>
									</Datepicker> 
								</div> 
								
							</CCol>
							
							
							<CCol lg="4">
								<div class="form-group"> 
									<label class="mb-1">Registration Type</label>
									<v-select 
										label="setting_name" 
										:options="[
											{
												setting_name: 'For Hire',
												id: 'FOR HIRE'
											},
											{
												setting_name: 'Private',
												id: 'PRIVATE'
											}, 
										]"
										:reduce="item => item.id"
										v-model="dataParams.registration_type"
										placeholder="-Select-" 
									>
										
									</v-select>
								</div>
								
							</CCol>
							
							<CCol lg="4">
								<div class="form-group">
									<label class="mb-1">Registration Date</label>
									<Datepicker 
										input-class="form-control bg-white" 
										v-model="dataParams.registration_date"
									>
									</Datepicker> 
									
								</div> 
							</CCol>
							
							<CCol lg="12">
								<label class="mb-1">Remarks</label>
								<CInput
				                
				                  v-model="dataParams.remarks" 
				                />
							</CCol> 
						</CRow>
		        </CCard>
				
				<CCard class="pl-2 pr-2">	
						<CRow class="mt-3">
								<CCol lg="12">
									<h6 >Photos </h6>					
								</CCol>
						</CRow>
                        <br/> 
						<CRow >
							
							<CCol lg="4">
								<label for="default_vehicle_photo" class="ml-4">Vehicle Picture</label><br>
								<input type="file" class="ml-4" id="default_vehicle_photo" style="width: 260px;"><br>
								<label class="ml-2" for="default_vehicle_photo" style="max-height: 200px; max-width:200px;">
									<img class="m-3" style="max-height: 180px; max-width:200px; cursor: pointer" id="display_default_vehicle_photo" src="https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg" v-if="!dataParams.vehicle_photo">
									<img class="m-3" style="max-height: 180px; max-width:200px; cursor: pointer" id="display_default_vehicle_photo" :src="dataParams.vehicle_photo" v-else>
								</label>
							</CCol> 
							<CCol lg="4">
								<label for="default_or_photo" class="ml-4">OR</label><br>
								<input type="file" class="ml-4" id="default_or_photo" style="width: 260px;"><br>
								<label class="ml-2" for="default_or_photo" style="max-height: 200px; max-width:200px;">
								<!-- <label for="default_or_photo" style="max-height: 180px; overflow: hidden;"> -->
									<img class="m-3" style="max-height: 180px; max-width:200px; cursor: pointer" id="display_default_or_photo" src="https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg" v-if="!dataParams.or_photo">
									<img class="m-3" style="max-height: 180px; max-width:200px; cursor: pointer" id="display_default_or_photo" :src="dataParams.or_photo" v-else>
								</label>
							</CCol> 
							<CCol lg="4">
								<label for="default_cr_photo" class="ml-4">CR</label><br>
								<input type="file" class="ml-4"  id="default_cr_photo" style="width: 260px;"><br>
								<label class="ml-2" for="default_cr_photo" style="max-height: 200px; max-width:200px;">
								<!-- <label for="default_cr_photo" style="max-height: 180px; overflow: hidden;"> -->
									<img class="m-3" style="max-height: 180px; max-width:200px; cursor: pointer" id="display_default_cr_photo" src="https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg" v-if="!dataParams.cr_photo">
									<img class="m-3" style="max-height: 180px; max-width:200px; cursor: pointer" id="display_default_cr_photo" :src="dataParams.cr_photo" v-else>
								</label>
							</CCol> 
							<CCol lg="4">
								<label for="default_insurance_photo" class="ml-4">Insurance</label><br>
								<input type="file" class="ml-4" id="default_insurance_photo" style="width: 260px;"><br>
								<!-- <label for="default_insurance_photo" style="max-height: 200px; overflow: hidden;"> -->
									<label class="ml-2" for="default_insurance_photo" style="max-height: 200px; max-width:200px;">
									<img class="m-3" style="max-height: 180px; max-width:200px; cursor: pointer" id="display_default_insurance_photo" src="https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg" v-if="!dataParams.insurance_photo">
									<img class="m-3" style="max-height: 180px; max-width:200px; cursor: pointer" id="display_default_insurance_photo" :src="dataParams.insurance_photo" v-else>
								</label>
							</CCol> 
							<CCol lg="4">
								<label for="default_pa_photo" class="ml-4">PA</label><br>
								<input type="file" class="ml-4" id="default_pa_photo" style="width: 260px;"><br>
								<label class="ml-2" for="default_pa_photo" style="max-height: 200px; max-width:200px;" >
									<img class="m-3" style="max-height: 180px; max-width:200px; cursor: pointer" id="display_default_pa_photo" src="https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg" v-if="!dataParams.pa_photo">
									<img class="m-3" style="max-height: 180px; max-width:200px; cursor: pointer" id="display_default_pa_photo" :src="dataParams.pa_photo" v-else>
								</label>
							</CCol> 
						</CRow>
				</CCard>
					
				</CCardBody>
			</form>
		</CCard>
	</div>
</template>
<script>
import config from "../../config.js";
import axios from "../../axios";
import Swal from "sweetalert2";
import Search from "./search";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
// import 'vue-select/dist/vue-select.css';
import "../../style.css";
import moment from "moment";

export default {
  mounted() {
    this.getVendor();
    this.getVehicleType();
    this.getCommodityType();
    this.getCurrentLocation();
    this.getMakerModel();
    this.getVehicleModel();
    this.getDepot();
    this.getOrigin();
    this.getCustomer();	

    document.getElementById("default_vehicle_photo").onchange = () => {
      var file = document.getElementById("default_vehicle_photo").files[0];
	  console.log('FILE', document.getElementById("default_vehicle_photo").files[0])
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);

      reader.onloadend = function (e) {
        document.getElementById("display_default_vehicle_photo").src =
          reader.result;
      }.bind(this);
    };

    document.getElementById("default_or_photo").onchange = () => {
      var file = document.getElementById("default_or_photo").files[0];
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);

      reader.onloadend = function (e) {
        document.getElementById("display_default_or_photo").src = reader.result;
      }.bind(this);
    };

    document.getElementById("default_cr_photo").onchange = () => {
      var file = document.getElementById("default_cr_photo").files[0];
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);

      reader.onloadend = function (e) {
        document.getElementById("display_default_cr_photo").src = reader.result;
      }.bind(this);
    };

    document.getElementById("default_insurance_photo").onchange = () => {
      var file = document.getElementById("default_insurance_photo").files[0];
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);

      reader.onloadend = function (e) {
        document.getElementById("display_default_insurance_photo").src =
          reader.result;
      }.bind(this);
    };

	document.getElementById("default_pa_photo").onchange = () => {
      var file = document.getElementById("default_pa_photo").files[0];
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);

      reader.onloadend = function (e) {
        document.getElementById("display_default_pa_photo").src =
          reader.result;
      }.bind(this);
    };

    if (this.$route.params.id != undefined) {
      this.editMode = true;
    }
    if (this.$route.params.id) {
      this.getData();
    }
  },
  data() {
    return {
      formModal: false,
      showViewModal: false,
      dataList: [],
      dataListInactive: [],
      depotName: "",
      depot_id: JSON.parse(window.localStorage.getItem("user_data")).depot_id,
      dataParams: {
        vehicle_type_id: "",
        maker_model_id: "",
        origin_id: "",
        vendor_id: "",
        driver_id: "",
        body_no: "",
        year: "",
        with_gps: "no",
        company_owned: true,
        plate_no: "",
        registration_date: "",
        asset_no: "",
        engine_no: "",
        engine_size: "",
        chassis_no: "",
        transmission_type: "",
        transfer_date: "",
        color: "",
        fuel_type: "",
        later_renewal_date: "",
        acquisition_date: "",
        acquisition_price: "",
        remarks: "",
        vehicle_photo: "",
        or_photo: "",
        cr_photo: "",
        insurance_photo: "",
        status: "ACTIVE",
        location:
          JSON.parse(window.localStorage.getItem("user_data")).depot_id != null
            ? JSON.parse(window.localStorage.getItem("user_data")).depot_id
            : "",
        breakdown_date: "",
        project_id: "",
		    current_location:"",

        mv_file_no: "",
        insurance_provider: "",
        insurance_policy_no: "",
        insurance_expiry: "",
        in_marine_policy_no: "",
        in_marine_expiry: "",
        ltfrb_case_no: "",
        ltfrb_expiry: "",
        cr_no: "",
        cr_date: "",
        or_no: "",
        or_date: "",
        commodity_type_id: "",
        registration_type: "",
        model_id: "",
      },
      editMode: false,
      selectedRow: {},
      vendorList: {
        data: [],
      },
      vehicleTypeList: {
        data: [],
      },
      commodityTypeList: {
        data: [],
      },
      makerModelList: {
        data: [],
      },
      vehicleModelList: {
        data: [],
      },
      depotList: {
        data: [],
      },
      originList: {
        data: [],
      },
      customerList: {
        data: [],
      },
      currentLocationList: {
        data: [],
      },
      isLoading: false,
      uploadPercentage: 0,
      config,
    };
  },
  name: "Tables",
  components: { Search, Datepicker, vSelect },
  methods: {
    backToTable() {
      this.$router.push("/data/vehicle");
    },

    getData() {
      axios
        .get(config.api_path + "/vehicle/" + this.$route.params.id)
        .then((response) => {
          this.dataParams = response.data.data;
          console.log(this.dataParams);
          if (this.dataParams.with_gps == 1) {
            this.dataParams.with_gps = "yes";
          } else {
            this.dataParams.with_gps = "no";
          }

          this.dataParams.model_id = this.dataParams.model_id
            ? parseInt(this.dataParams.model_id)
            : null;
          this.dataParams.commodity_type_id = this.dataParams.commodity_type_id
            ? parseInt(this.dataParams.commodity_type_id)
            : this.dataParams.commodity_type_id;
          this.dataParams.location = this.dataParams.location
            ? parseInt(this.dataParams.location)
            : this.dataParams.location;		 
        });
    },
    getData(){
			this.$showLoading(true)
			axios.get(config.api_path+"/vehicle/"+this.$route.params.id)
			.then(response=>{
				this.$showLoading(false)
				this.dataParams = response.data.data;
				console.log(this.dataParams)
				if(this.dataParams.with_gps == 1){
					this.dataParams.with_gps = "yes";
				}
				else{
					this.dataParams.with_gps = "no";
				}

				if(this.dataParams.vehicle_photo_s3 == 0)
					this.dataParams.vehicle_photo = this.dataParams.vehicle_photo ? config.main_path.replace('/index.php', '')+'/uploads/'+this.dataParams.vehicle_photo : '';
				else
					this.dataParams.vehicle_photo = this.dataParams.vehicle_photo_s3;

				if(this.dataParams.cr_photo_s3 == 0)
					this.dataParams.cr_photo =this.dataParams.cr_photo ? config.main_path.replace('/index.php', '')+'/uploads/'+this.dataParams.cr_photo : '';
				else
					this.dataParams.cr_photo = this.dataParams.cr_photo_s3;

				if(this.dataParams.or_photo_s3 == 0)
					this.dataParams.or_photo = this.dataParams.or_photo ? config.main_path.replace('/index.php', '')+'/uploads/'+this.dataParams.or_photo : '';
				else
					this.dataParams.or_photo = this.dataParams.or_photo_s3;

				if(this.dataParams.insurance_photo_s3 == 0)
					this.dataParams.insurance_photo = this.dataParams.insurance_photo ? config.main_path.replace('/index.php', '')+'/uploads/'+this.dataParams.insurance_photo : '';
				else
					this.dataParams.insurance_photo = this.dataParams.insurance_photo_s3;

				if(this.dataParams.pa_photo_s3 == 0)
					this.dataParams.pa_photo = this.dataParams.pa_photo ? config.main_path.replace('/index.php', '')+'/uploads/'+this.dataParams.pa_photo : "";
				else
					this.dataParams.pa_photo = this.dataParams.pa_photo_s3;

				this.dataParams.model_id = this.dataParams.model_id ? parseInt(this.dataParams.model_id) : null;
				this.dataParams.commodity_type_id = (this.dataParams.commodity_type_id) ? parseInt(this.dataParams.commodity_type_id) : this.dataParams.commodity_type_id;
				this.dataParams.location = this.dataParams.location ? parseInt(this.dataParams.location) : this.dataParams.location;
			})
			.catch(err => {
			this.$showLoading(false)
		}) 
  },
    submit() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      var formData = new FormData();

      console.log(this.dataParams, "AAAAAAA");

      for (const d in this.dataParams) {
        console.log(d);
        this.dataParams[d] = this.dataParams[d] ?? "";
      }

      if (
        this.dataParams.project_id != "" &&
        this.dataParams.project_id != null
      ) {
        formData.append("project_id", this.dataParams.project_id);
      }

	  if (
        this.dataParams.current_location != "" &&
        this.dataParams.current_location != null
      ) {
		if(typeof this.dataParams.current_location == 'number')
        	formData.append("current_location_id", this.dataParams.current_location);
		else
		formData.append("current_location_id", this.dataParams.current_location.id);
      }

      if (
        this.dataParams.vehicle_type_id != "" &&
        this.dataParams.vehicle_type_id != null
      ) {
        formData.append("vehicle_type_id", this.dataParams.vehicle_type_id);
      }
      if (
        this.dataParams.maker_model_id != "" &&
        this.dataParams.maker_model_id != null
      ) {
        formData.append("maker_model_id", this.dataParams.maker_model_id);
      }
      if (
        this.dataParams.origin_id != "" &&
        this.dataParams.origin_id != null
      ) {
        formData.append("origin_id", this.dataParams.origin_id);
      }
      
      if (
        this.dataParams.driver_id != "" &&
        this.dataParams.driver_id != null
      ) {
        formData.append("driver_id", this.dataParams.driver_id);
      }
      formData.append("body_no", this.dataParams.body_no);
      formData.append("year", this.dataParams.year);
      if (this.dataParams.with_gps != "") {
        formData.append("with_gps", this.dataParams.with_gps);
      }
      formData.append("company_owned", this.dataParams.company_owned ? 1 : 0);
      formData.append("plate_no", this.dataParams.plate_no);
      if (
        this.dataParams.registration_date != "" &&
        this.dataParams.registration_date != null
      ) {
        formData.append(
          "registration_date",
          moment(this.dataParams.registration_date).format("YYYY-MM-DD")
        );
      }
      if (
        this.dataParams.breakdown_date != "" &&
        this.dataParams.breakdown_date != null
      ) {
        formData.append(
          "breakdown_date",
          moment(this.dataParams.breakdown_date).format("YYYY-MM-DD")
        );
      }
      formData.append("asset_no", this.dataParams.asset_no);
      formData.append("engine_no", this.dataParams.engine_no);
      formData.append("engine_size", this.dataParams.engine_size);
      formData.append("chassis_no", this.dataParams.chassis_no);
      formData.append("transmission_type", this.dataParams.transmission_type);
      if (
        this.dataParams.transfer_date != "" &&
        this.dataParams.transfer_date != null
      ) {
        formData.append(
          "transfer_date",
          moment(this.dataParams.transfer_date).format("YYYY-MM-DD")
        );
      }
      formData.append("color", this.dataParams.color);
      formData.append("fuel_type", this.dataParams.fuel_type);
      if (
        this.dataParams.later_renewal_date != "" &&
        this.dataParams.later_renewal_date != null
      ) {
        formData.append(
          "later_renewal_date",
          moment(this.dataParams.later_renewal_date).format("YYYY-MM-DD")
        );
      }
      if (
        this.dataParams.acquisition_date != "" &&
        this.dataParams.acquisition_date != null
      ) {
        formData.append(
          "acquisition_date",
          moment(this.dataParams.acquisition_date).format("YYYY-MM-DD")
        );
      }
      formData.append("acquisition_price", this.dataParams.acquisition_price);
      formData.append("remarks", this.dataParams.remarks);
      formData.append("status", this.dataParams.status);
      formData.append("location", this.dataParams.location);
      if (
        this.dataParams.insurance_expiry != "" &&
        this.dataParams.insurance_expiry != null
      ) {
        formData.append(
          "insurance_expiry",
          moment(this.dataParams.insurance_expiry).format("YYYY-MM-DD")
        );
      }
      if (
        this.dataParams.in_marine_expiry != "" &&
        this.dataParams.in_marine_expiry != null
      ) {
        formData.append(
          "in_marine_expiry",
          moment(this.dataParams.in_marine_expiry).format("YYYY-MM-DD")
        );
      }
      if (
        this.dataParams.ltfrb_expiry != "" &&
        this.dataParams.ltfrb_expiry != null
      ) {
        formData.append(
          "ltfrb_expiry",
          moment(this.dataParams.ltfrb_expiry).format("YYYY-MM-DD")
        );
      }
      if (this.dataParams.cr_date != "" && this.dataParams.cr_date != null) {
        formData.append(
          "cr_date",
          moment(this.dataParams.cr_date).format("YYYY-MM-DD")
        );
      }
      if (this.dataParams.or_date != "" && this.dataParams.or_date != null) {
        formData.append(
          "or_date",
          moment(this.dataParams.or_date).format("YYYY-MM-DD")
        );
      }
      formData.append("mv_file_no", this.dataParams.mv_file_no);
      formData.append("insurance_provider", this.dataParams.insurance_provider);
      formData.append(
        "insurance_policy_no",
        this.dataParams.insurance_policy_no
      );
      formData.append(
        "in_marine_policy_no",
        this.dataParams.in_marine_policy_no
      );
      formData.append("ltfrb_case_no", this.dataParams.ltfrb_case_no);
      formData.append("cr_no", this.dataParams.cr_no);
      formData.append("or_no", this.dataParams.or_no);

      formData.append("commodity_type_id", this.dataParams.commodity_type_id);
      formData.append("registration_type", this.dataParams.registration_type);      

      if (document.getElementById("default_vehicle_photo").files[0]) {
        formData.append(
          "vehicle_photo",
          document.getElementById("default_vehicle_photo").files[0]
        );
      }
      if (document.getElementById("default_or_photo").files[0]) {
        formData.append(
          "or_photo",
          document.getElementById("default_or_photo").files[0]
        );
      }
      if (document.getElementById("default_cr_photo").files[0]) {
        formData.append(
          "cr_photo",
          document.getElementById("default_cr_photo").files[0]
        );
      }
      if (document.getElementById("default_insurance_photo").files[0]) {
        formData.append(
          "insurance_photo",
          document.getElementById("default_insurance_photo").files[0]
        );
      }

	  if (document.getElementById("default_pa_photo").files[0]) {
        formData.append(
          "pa_photo",
          document.getElementById("default_pa_photo").files[0]
        );
      }

      if (this.dataParams.vendor_id != "" && this.dataParams.vendor_id != null) {
          if(this.dataParams.company_owned)
            formData.append("vendor_id", null);
          else
            formData.append("vendor_id", this.dataParams.vendor_id);
      }

      formData.append("model_id", this.dataParams.model_id);
      var id = "";
      if (this.editMode) {
        formData.append("method", "_PUT");
        id = "/" + this.$route.params.id + "?_method=PUT";
      }      
      axios
        .post(config.api_path + "/vehicle" + id, formData, {
          onUploadProgress: (prog) => {
            var uploadPercentage = parseInt(
              Math.round((prog.loaded / prog.total) * 100)
            );
            this.uploadPercentage = uploadPercentage;
          },
        })
        .then((response) => {
          if (response.data.status == "failed") {
            Swal.fire({
              title: "Error!",
              text: response.data.message,
              icon: "error",
            });
            return;
          }
          var text = "Vehicle successfully added!";
          if (this.editMode) {
            text = "Vehicle successfully updated!";
          }
          Swal.fire({
            title: "Success!",
            text,
            icon: "success",
          });
          this.formModal = false;
          this.editMode = false;
          this.dataParams = {
            vehicle_type_id: "",
            maker_model_id: "",
            origin_id: "",
            vendor_id: "",
            driver_id: "",
            body_no: "",
            year: "",
            with_gps: "",
            company_owned: true,
            plate_no: "",
            registration_date: "",
            asset_no: "",
            engine_no: "",
            engine_size: "",
            chassis_no: "",
            transmission_type: "",
            transfer_date: "",
            color: "",
            fuel_type: "",
            later_renewal_date: "",
            acquisition_date: "",
            acquisition_price: "",
            remarks: "",
            vehicle_photo: "",
            or_photo: "",
            cr_photo: "",
            insurance_photo: "",
            status: "ACTIVE",
            location: "",
            commodity_type_id: "",
            registration_type: "",            
            model_id: "",
          };
          // this.getData();
          // this.getDataInactive();
          this.isLoading = false;
          this.$router.push("/data/view_vehicle/" + response.data.data.id);
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    locationChanged(ev) {
			let depot = [], depot_id = '';
			let customer = this.customerList.data.filter(item => item.id == ev);
			if(customer.length) {
				depot_id = customer[0].depot_id;
			}
			depot = this.depotList.data.filter(item => item.id == depot_id);
			
			if(depot.length) {
				this.dataParams.depot = depot[0];
				this.dataParams.depot_id = depot[0].id;
			}
			else {
				this.dataParams.depot = null;
				this.dataParams.depot_id = null;
			}
		},

    getVendor() {
      axios
        .get(config.api_path + "/vendor?page=1&limit=10000000")
        // axios.get(config.api_path+'/vendor?page=1&limit=10000000')
        .then((response) => {
          this.vendorList = response.data;
          this.vendorList.data = this.vendorList.data.map((value, index) => {
            value.value = value.id;
            value.label = value.vendor_name;
            return value;
          });
          this.vendorList.data = this.vendorList.data.filter((value, index) => {
            return (
              value.vendor_type_name.setting_name.toLowerCase() ==
                "trucking services" ||
              value.vendor_type_name.setting_name.toLowerCase() == "trucker"
            );
          });
        });
    },

    getVehicleType() {
      axios
        .get(config.api_path + "/reference/settings-list", {
          params: {
            setting_type: "vehicle_type",
          },
        })
        .then((response) => {
          this.vehicleTypeList = response.data;
        });

      // axios.get(config.api_path+'/setting?setting_type=vehicle_type&page=1&limit=10000000')
      // .then(response => {
      // 	this.vehicleTypeList = response.data;
      // })
    },
    getCommodityType() {
      axios
        .get(config.api_path + "/reference/settings-list", {
          params: {
            setting_type: "commodity_type",
          },
        })
        .then((response) => {
          this.commodityTypeList = response.data;
        });
			// formData.append('company_owned', this.dataParams.company_owned ? 1 : 0);
	    // 	if(this.dataParams.vendor_id != "" && this.dataParams.vendor_id != null){
			// 	if(this.dataParams.company_owned) {
			// 		// formData.append('vendor_id', null);
			// 	}
	    // 		else formData.append('vendor_id', this.dataParams.vendor_id);
	    // 	}
	    	

      // axios.get(config.api_path+'/setting?setting_type=commodity_type&page=1&limit=10000000')
      // .then(response => {
      // 	this.commodityTypeList = response.data;
      // })
    },

    getMakerModel() {
      axios
        .get(config.api_path + "/reference/settings-list", {
          params: {
            setting_type: "maker_model",
          },
        })
        .then((response) => {
          this.makerModelList = response.data;
        });

      // axios.get(config.api_path+'/setting?setting_type=maker_model&page=1&limit=10000000')
      // .then(response => {
      // 	this.makerModelList = response.data;
      // })
    },
    getVehicleModel() {
      axios
        .get(config.api_path + "/reference/settings-list", {
          params: {
            setting_type: "vehicle_model",
          },
        })
        .then((response) => {
          this.vehicleModelList = response.data;
        });

      // axios.get(config.api_path+'/setting?setting_type=vehicle_model&page=1&limit=10000000')
      // .then(response => {
      // 	this.vehicleModelList = response.data;
      // })
    },

    getDepot(){

          axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'depot'
            }
          })
        .then(response => {
          this.depotList = response.data;
        

        let depot_id = this.depot_id;//this.user_data?.depot_id;
        if(depot_id) {
          this.depotList.data = this.depotList.data.filter(item => item.id == depot_id)
          let depot = this.depotList.data.filter(item => item.id == depot_id);
          if(depot.length) {
          this.$set(this.dataParams,'depot',depot[0])

          this.dataParams.depot_id = depot_id;
        }
        }
        })

        },
    getOrigin() {
      axios
        .get(config.api_path + "/reference/settings-list", {
          params: {
            setting_type: "origin",
          },
        })
        .then((response) => {
          this.originList = response.data;
        });
    },

    // getOrigin(){

    // 	axios.get(config.api_path+'/setting?setting_type=origin&page=1&limit=10000000')
    // 	.then(response => {
    // 		this.originList = response.data;
    // 	})

    // },
    getCustomer() {
      axios
        .get(config.api_path + "/reference/customer-list", {
          params: {
            show_all: true,			
          },
        })
        .then((response) => {
          this.customerList = response.data;
      
                    
        });
    },
    getCurrentLocation() {
      axios
        .get(config.api_path + "/reference/customer-list", {
          params: {
            show_all: true,			
          },
        })
        .then((response) => {
          this.currentLocationList = response.data;
          this.currentLocationList.data = this.currentLocationList.data.filter(i => i.depot_id == this.depot_id || !this.depot_id)
                    
        });
    },
        
    updateCompanyOwned(e) {
      this.dataParams.company_owned = e;
    },
  },
};
</script>
